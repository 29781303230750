class Touch {
  constructor() {

  }

  init() {
    //アコーディオンをクリックした時の動作
$('.p-system__btnImg').on('click', function() {//タイトル要素をクリックしたら
  $('.p-system__btn--after').hide(500);//クラス名.boxがついたすべてのアコーディオンを閉じる
    
  var findElm = $(this).next(".p-system__btn--after");//タイトル直後のアコーディオンを行うエリアを取得
    
  if($(this).hasClass('close')){//タイトル要素にクラス名closeがあれば
    $(this).removeClass('close');//クラス名を除去    
  }else{//それ以外は
    $('.close').removeClass('close'); //クラス名closeを全て除去した後
    $(this).addClass('close');//クリックしたタイトルにクラス名closeを付与し
    $(findElm).show(500);//アコーディオンを開く
  }
});



  }
};

export default Touch;
