/**
 * コモンクラス
 * @constructor
 */
class Common {
  constructor() {

  }
  /**
   * コモン
   */
  init() {

    $(window).on('load scroll', () => {

      this.sct = $(window).scrollTop();
      this.stayPosition = $(window).scrollTop();
      this.scrollHeight = $(document).height();
      this.toTopPosition();
      this.scroll();
      this.navCurrent();

    });

    $('.l-footer__toTop').on('click', () => {

      this.toTop();

    });

  }

  toTopPosition() {

    let topBtn = $('.l-footer__toTop');

    if (this.stayPosition > 100) {
      topBtn.addClass('is-show').fadeIn();
    } else {
      topBtn.removeClass('is-show').fadeOut();
    }

    let scrollPosition = $(window).height() + this.stayPosition;
    let footHight = $('.l-footer').innerHeight();
    
    if (this.scrollHeight - scrollPosition <= footHight) {
      topBtn.css({
        "position": "absolute",
        "bottom": footHight + 20,
      });
    } else {
      topBtn.css({
        "position": "fixed",
        "bottom": "20px",
      });
    };

  }

  toTop() {

    $('body,html').animate({
      scrollTop: 0
    }, 1000);
    return false;

  }
  scroll(){
    jQuery(function() {

      var footer = $('.l-footer').innerHeight(); // footerの高さを取得
      
      window.onscroll = function () {
        var point = window.pageYOffset; // 現在のスクロール地点 
        var docHeight = $(document).height(); // ドキュメントの高さ
        var dispHeight = $(window).height(); // 表示領域の高さ
      
        if(point > docHeight-dispHeight-footer){ // スクロール地点>ドキュメントの高さ-表示領域-footerの高さ
          $('.scrolldown').addClass('is-hidden'); //footerより下にスクロールしたらis-hiddenを追加
        }else{
          $('.scrolldown').removeClass('is-hidden'); //footerより上にスクロールしたらis-hiddenを削除
        }
      };
      });

  }
  navCurrent(){

    $(function() {
 
      var stepMenu = function() {
      var array = {
        '#kv': 0,
        '#concept': 0,
        '#hydrogen': 0,
        '#feature': 0,
        '#history': 0,
        '#spec': 0,
        '#project': 0,
        '#news': 0,
      };
     
      var $globalNavi = new Array();
     
      for (var key in array) {
        if ($(key).offset()) {
          array[key] = $(key).offset().top - 10;
          $globalNavi[key] = $('.l-header__menu li a[href="' +key+ '"]');
        }
      }
     
      $(window).scroll(function () {
        for (var key in array) {
          if ($(window).scrollTop() > array[key] - 200) {
            $('.l-header__menu li a').each(function() {
              $(this).removeClass('current');
            });
          $globalNavi[key].addClass('current');
          }
        }
      });
      }
     
      stepMenu();
    });

    
  }


}

export default Common;
