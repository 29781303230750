class Movie {
  constructor() {

  }

  init() {

    $(".p-concept__movieThumb").on("click", function () {

      videoControl("playVideo", $(this).prev("iframe"));
      $(this).hide();
      // if ($('.sp-spacer').is(':visible')) {
      //   window.open('https://www.youtube.com/embed/ZRCdORJiUgU?rel=0&autoplay=1', '_blank');
      // } else {
      // }
    });

    function videoControl(action, tgt) {
      var $playerWindow = $(tgt)[0].contentWindow;
      $playerWindow.postMessage('{"event":"command","func":"' + action + '","args":""}', '*');
    }



  }
};

export default Movie;
