
import 'desvg';
import objectFitImages from 'object-fit-images';
import picturefill from 'picturefill';
import SmoothScroll from 'smooth-scroll';
import Swiper from 'swiper';

/**
 * import modules
 */
import Movie from './modules/movie';
import Common from './modules/common';
import Touch from './modules/touch';
import Gnav from './modules/gnav';
import { nodeName } from 'jquery';

/**
 * Mainクラス
 * @constructor
 */
class Main {
  constructor() {
    this.setup();
  }
  /**
   * インスタンス生成時に実行する処理
   */
  setup() {
    document.addEventListener('DOMContentLoaded', () => {
      this.created();

    })
    window.addEventListener('load', () => {
      this.mounted();
    });
  }
  /**
   * ページ読み込み時に実行する処理
   */
  created() {
    picturefill();

    const movie = new Movie();
    movie.init();

    const common = new Common();
    common.init();

    const touch = new Touch();
    touch.init();

    const gnav = new Gnav();
    gnav.init();

  }
  /**
   * 画像読み込み後に実行する処理
   */
  mounted() {


    this.init_deSVG('.js-desvg');

    this.init_objectFitImages('.object-fit');

    this.init_smoothScroll();

    this.init_swiper();
  }

  init_swiper(){
    var mySwiper = new Swiper('.swiper-container', {
      initialSlide: 1,
      centeredSlides: true,
      spaceBetween: 20,
      slidesPerView: 3,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      autoplay: {
          delay: 3000,
      },
      loop: true,
      breakpoints: {
        750: {
          slidesPerView: 1.11,
          spaceBetween: 10,
        },
        9999:{
          autoplay:false,
        }
      },

    });


    
}



  /**
   * ライブラリ/deSVG
   * インラインsvgをsvgタグに変換
   * https://github.com/benhowdle89/deSVG
   * @param {Element} target ターゲットとなる要素
   */
  init_deSVG(target) {
    deSVG(target, true)
  }

  /**
   * ライブラリ/object-fit-images
   * css object-fit のポリフィル
   * https://github.com/fregante/object-fit-images
   * @param {Element} target ターゲットとなる要素
   */
  init_objectFitImages(target) {
    objectFitImages(target);
  }

  /**
   * ライブラリ/picturefill
   * html pictureタグのポリフィル
   * https://scottjehl.github.io/picturefill/
   */
  init_picturefill() {
    picturefill();
  }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
  init_smoothScroll() {
    /**
     * ターゲットとなる要素
     * @type {Element}
     */
    const targetElement = 'a[href*="#"]';

    /**
     * オプション
     * @type {object}
     */
    const option = {
      speed: 1000,
      easing: 'easeInOutCubic',
      speedAsDuration: true,
      //header: '.l-header' 固定ヘッダーの場合
    }

    new SmoothScroll(targetElement, option);
  }
}

export default new Main();
