class Gnav {
  constructor() {

  }

  init() {
    $(".openbtn1").click(function () {//ボタンがクリックされたら
      $(this).toggleClass('active');//ボタン自身に activeクラスを付与し
        $("#g-nav").toggleClass('panelactive');//ナビゲーションにpanelactiveクラスを付与
        $(".l-header__cover").toggleClass('active');//ナビゲーションにpanelactiveクラスを付与

    });
    
    $("#g-nav a").click(function () {//ナビゲーションのリンクがクリックされたら
        $(".openbtn1").removeClass('active');//ボタンの activeクラスを除去し
        $("#g-nav").removeClass('panelactive');//ナビゲーションのpanelactiveクラスも除去
        $(".l-header__cover").removeClass('active');//ナビゲーションのpanelactiveクラスも除去
    });


  }
};

export default Gnav;
